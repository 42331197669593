import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./UserLayout.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { Navbar, Footer } from "../../../components/common";
// import { eventData } from "../../../data/eventData";
import { Selector } from "../../../components";
import { FiSearch } from "react-icons/fi";
import { GetService } from "./../../../store/actions";
import { useDispatch } from "react-redux";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { OccasionContext } from "../../../contexts/OccasionContext";
import { useSelector } from "react-redux";
import useQueryParams from "../../../hooks/useQueryParams";
import { objToStr, removeEmpty } from "../../../utils";
import { GoogleAutoComplete } from "../../../components/GoogleAutoComplete/GoogleAutoComplete";

const UserLayout = () => {
  const [cityValue, setCityValue] = useState("");
  const dispatch = useDispatch();
  const { occasions } = useSelector((s) => s.occasionReducer);
  const { city, occassion, setQueryParam } = useQueryParams({
    city: "",
    occassion: "",
  });
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState({
    city: "",
    occassion: "",
  });

  useEffect(() => {
    setSearchData((pre) => ({ ...pre, city }));
  }, [city]);

  useEffect(() => {
    setCityValue(searchData.city);
  }, [searchData.city]);

  useEffect(() => {
    setSearchData((pre) => ({ ...pre, occassion }));
  }, [occassion]);

  const handleSearch = () => {
    navigate(`/search-results?${objToStr(removeEmpty(searchData))}`);
  };

  const handleChange = useCallback((e) => {
    setCityValue(e.target.value);
    if (e.target.value === "") {
      setSearchData((pre) => ({ ...pre, city: "" }));
    }
  }, []);

  return (
    <>
      <div className="userside">
        <div className="userside-navbar">
          <Navbar>
            <div className="userlayout-navbar-nav">
              <div className="hero-container-content-form">
                <Selector
                  selected={
                    occasions?.find((o) => o?._id === searchData.occassion)
                      ?.name || "Choose Occasion"
                  }
                  setSelected={(v) => {
                    setSearchData((pre) => ({ ...pre, occassion: v._id }));
                  }}
                  data={occasions}
                  className="selector-rounded selector-h"
                  fullObj
                />

                <GoogleAutoComplete
                  onChange={(place) => {
                    setSearchData((pre) => ({
                      ...pre,
                      city: place.description,
                    }));
                  }}
                  className="hero-container-content-input"
                  type="text"
                  placeholder="Enter City/Town"
                  value={cityValue}
                  onValueChange={handleChange}
                />

                <div
                  onClick={() => handleSearch()}
                  className="hero-container-content-form-button"
                >
                  <div>
                    <FiSearch />
                  </div>
                </div>
              </div>
            </div>
          </Navbar>

          <div className="userlayout-navbar-mobile">
            <div className="hero-container-content-form">
              <Selector
                selected={
                  occasions?.find((o) => o?._id === occassion)?.name ||
                  "Choose Occasion"
                }
                setSelected={(v) => {
                  setQueryParam({ occassion: v._id });
                }}
                data={occasions}
                className="selector-rounded selector-h"
                fullObj
              />
              <GoogleAutoComplete
                onChange={(place) => {
                  setSearchData((pre) => ({
                    ...pre,
                    city: place.description,
                  }));
                }}
                className="hero-container-content-input"
                type="text"
                placeholder="Enter City/Town"
                value={cityValue}
                onValueChange={handleChange}
              />

              <div
                onClick={() => handleSearch()}
                className="hero-container-content-form-button"
              >
                <div>
                  <FiSearch />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="userside-outlet">
          <Outlet />
        </div>
        <div className="userside-footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default UserLayout;

import { useEffect, useRef, useState } from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import styled from "@emotion/styled";

const GoogleAutoCompleteStyled = styled.div`
  position: relative;

  & > ul {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(calc(100% + 6px));
    background-color: white;
    padding: 4px;
    border-radius: 6px;
    display: grid;
    grid-template-columns: 1fr;
    & > li {
      display: grid;
      grid-template-columns: 1fr;

      &.item {
        padding: 4px 8px;
        border-radius: 6px;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        & > p {
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      & > p.powered {
        padding-top: 12px;
        display: grid;
        grid-template-columns: auto auto;
        gap: 4px;
        align-content: center;
        align-items: center;
        justify-content: end;

        & > span {
          font-size: 12px;
        }
        & > img {
          margin-left: auto;
          width: 50px;
        }
      }
    }
  }
`;

export const GoogleAutoComplete = ({
  value: _value,
  onValueChange,
  onChange,
  ...rest
}) => {
  const closeTimeoutRef = useRef(null);
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    refreshSessionToken,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    options: {
      types: ["(cities)"],
      componentRestrictions: { country: "uk" },
    },
    debounce: 300,
    sessionToken: true,
  });
  const [value, setValue] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  return (
    <GoogleAutoCompleteStyled
      onFocus={() => setShowDropDown(true)}
      onBlur={(e) => {
        closeTimeoutRef.current = setTimeout(() => {
          setShowDropDown(false);
        }, 100);
      }}
    >
      <input
        {...rest}
        value={_value !== undefined ? _value : value}
        onChange={(evt) => {
          onValueChange?.(evt);
          getPlacePredictions({ input: evt.target.value });
          setValue(evt.target.value);
        }}
      />

      {showDropDown && placePredictions.length !== 0 && (
        <ul className="dropdown">
          {placePredictions.map((obj, index) => (
            <li
              key={index}
              className="item"
              onClick={() => {
                setValue(obj.description);
                onValueChange?.({
                  target: {
                    value: obj.description,
                    name: rest.name,
                    id: rest.id,
                  },
                });
                refreshSessionToken();
                onChange(obj);
              }}
            >
              <p>{obj.description}</p>
            </li>
          ))}
          <li>
            <p className="powered">
              <span>Powered by </span>
              <img src="/google_on_white.png" alt="Powered by Google" />
            </p>
          </li>
        </ul>
      )}

      {showDropDown &&
        placePredictions.length === 0 &&
        isPlacePredictionsLoading && (
          <ul className="dropdown">
            <li>
              <p>Loading...</p>
            </li>
          </ul>
        )}
    </GoogleAutoCompleteStyled>
  );
};

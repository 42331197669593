// Upgrade Plain

import React, { useEffect, useMemo, useRef, useState } from "react";
import "./UpgradePlain.scss";
import { useFormik } from "formik";
import { Selector } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  AddPaymentCardBeforeSubscription,
  GetPaymentCard,
  clearErrors,
  clearMessages,
  getCouponDetails,
  updateVendorMembership,
} from "../../../../store/actions";
import { useLocation, useNavigate } from "react-router-dom";
import {
  couponFormSchema,
  postalFormSchema,
  subscriptionFormSchema,
} from "../../../../schema";
import { getMonthsFromToday } from "../../../../utils";
import toast from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "@mui/material";
import axios from "axios";
import { MemberShipCard } from "../checkoutPage/MembershipCard";
import useUser from "../../../../hooks/useUser";
import { GoogleAutoComplete } from "../../../../components/GoogleAutoComplete/GoogleAutoComplete";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const intialCouponDetails = {
  startAt: new Date().toLocaleDateString(),
  percentageOff: 0,
  appliedCouponId: "",
};

const UpgradePlain = () => {
  const {
    details: cardsDetails,
    errors,
    message,
    loading,
    loading2,
    subscriptionLoading,
  } = useSelector((state) => state.vendorReducer);

  const [details, setDetails] = useState({});
  const vendor = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const storedAddressDetails = useMemo(
    () => JSON.parse(localStorage.getItem("paymentAddressDetails") || "{}"),
    []
  );
  useEffect(() => {
    const mainCard = cardsDetails.find((card) => {
      return card.payment_id === vendor.defaultPaymentId;
    });
    setDetails(mainCard);
  }, [cardsDetails]);

  const { search } = useLocation();
  const queryParams = useMemo(() => {
    const query = new URLSearchParams(search);
    return {
      _id: query.get("_id"),
      name: query.get("name"),
      price: query.get("price") ? Number(query.get("price")) : null,
      productId: query.get("productId"),
    };
  }, [search]);

  const [couponDetails, setCouponDetails] = useState(intialCouponDetails);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [postalCode, setpostalCode] = useState(null);
  const [ispostalvalid, setispostalvalid] = useState(false);
  const [city, setCity] = useState(null);
  const [localities, setLocalities] = useState(null);
  const [selectedLocality, setselectedLocality] = useState("Choose Locality");

  const vendorPackages = {
    Bronze: {
      features: [
        "Bronze Vendor Package",
        "Ideal for Starters",
        "Advertise one service",
        "Perfect for small businesses and freelancers",
      ],
    },
    Silver: {
      features: [
        "Silver Vendor Package",
        "Grow Your Presence",
        "Promote up to three services",
        "Suitable for growing service providers",
      ],
    },
    Gold: {
      features: [
        "Gold Vendor Package - £21.99/month",
        "For Established Professionals",
        "Highlight up to six services",
        "Tailored for established event professionals",
      ],
    },
    Platinum: {
      features: [
        "Platinum Vendor Package - £29.99/month",
        "Limitless Advertising",
        "Advertise an unlimited number of services",
        "Expand your reach nationally",
        "Designed for large event companies",
      ],
    },
  };
  const couponFormik = useFormik({
    initialValues: {
      couponId: "",
    },
    validationSchema: couponFormSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(
        getCouponDetails(
          values.couponId,
          (data) => {
            if (
              data?.applies_to?.products &&
              data?.applies_to?.products?.includes(queryParams.productId)
            ) {
              setCouponDetails({
                appliedCouponId: values.couponId,
                percentageOff: data?.percent_off || 0,
                startAt: getMonthsFromToday(data?.duration_in_months || 0),
              });
            } else {
              setCouponDetails(intialCouponDetails);
              toast.error(
                `You can't use this promo code for ${queryParams.name} subscription.`
              );
            }
          },
          () => {
            setCouponDetails(intialCouponDetails);
          }
        )
      );
    },
  });

  const subscriptionFormik = useFormik({
    initialValues: {
      storeAddress: "",
    },
    validationSchema: subscriptionFormSchema,

    onSubmit: async (values) => {
      if (details?.card?.number) {
        dispatch(
          updateVendorMembership({
            subscriptionId: queryParams._id,
            coupon: couponDetails.appliedCouponId || undefined,
          })
        );

        return;
      }

      if (!stripe || !elements) {
        toast.error("Stripe library hasn't loaded yet! Please try again.");
        setIsConfirmModalOpen(false);
        return;
      }

      const cardElement = elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        console.log(error);
        toast.error(
          "Something went wrong with token creation. Please try again!"
        );
        setIsConfirmModalOpen(false);
        return;
      }

      dispatch(
        AddPaymentCardBeforeSubscription({ id: token.id }, () => {
          dispatch(
            updateVendorMembership({
              subscriptionId: queryParams._id,
              coupon: couponDetails.appliedCouponId || undefined,
            })
          );
        })
      );
    },
  });
  const postalFormik = useFormik({
    initialValues: {
      postalCode: "",
    },
    validationSchema: postalFormSchema,
    onSubmit: async (values) => {
      localStorage.setItem("paymentAddressDetails", JSON.stringify(values));
      const postalresult = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${values.postalCode}|country:UK&key=AIzaSyA1OKBW722GCRyhTTYsQkM6F4mwBLV5eKY`
      );
      console.log(postalresult);
      if (postalresult.data.status == "OK") {
        setispostalvalid(true);
        const city = postalresult.data.results[0].formatted_address;
        setCity(city);
        if ("postcode_localities" in postalresult.data.results[0]) {
          setLocalities(postalresult.data.results[0].postcode_localities);
          console.log(localities);
        } else {
          setLocalities(null);
        }
      } else {
        toast.error("Please enter a valid postal code");
      }
    },
  });

  useEffect(() => {
    if (
      typeof queryParams.price !== "number" ||
      !queryParams.name ||
      !queryParams._id ||
      !queryParams.productId
    ) {
      navigate("/manage-membership", { replace: true });
      toast.error("You are accessing this page in forbidden way.");
      return;
    }

    if (vendor?.subscription?.name === queryParams.name) {
      toast.error("You have already subscribed to this plan.");
      navigate("/manage-membership", { replace: true });
      return;
    }

    dispatch(GetPaymentCard());
  }, [dispatch, navigate, queryParams, vendor.subscription.name]);

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
      setIsConfirmModalOpen(false);
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/vender-dashboard"), 2000);
      setIsConfirmModalOpen(false);
    }
  }, [dispatch, errors, message, navigate]);

  return loading ? (
    <div className="center-loader-nlkj21j3">
      <Puff
        height="60"
        width="60"
        radius="6"
        color="black"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  ) : (
    <>
      <div className="unqiue-id-aslkdjf2325k2j">
        <div className="top">
          <div className="box-2">
            <h2>Account Details</h2>
            <div className="box">
              <div>
                <h6>Email</h6>
                <p>{vendor?.email || "--"}</p>
              </div>
              <div>
                <h6>Full Name</h6>
                <p>{vendor?.fullName || "--"}</p>
              </div>
              {details?.card?.number ? (
                <>
                  <div>
                    <h6>Card Number</h6>
                    <p>**** **** **** {details?.card?.number || "****"}</p>
                  </div>
                  <div>
                    <h6>CVC</h6>
                    <p>***</p>
                  </div>
                </>
              ) : (
                <PaymentForm />
              )}
              <form onSubmit={postalFormik.handleSubmit}>
                <InputField
                  label="Postal Code"
                  type="text"
                  placeholder="Enter Postal Code"
                  onChange={postalFormik.handleChange}
                  onBlur={postalFormik.handleBlur}
                  value={postalFormik.values.postalCode}
                  error={
                    postalFormik.touched.postalCode &&
                    postalFormik.errors.PostalCode
                  }
                  name="postalCode"
                />
                <button
                  style={{ marginTop: "2rem" }}
                  className="button-9274982734987324afasdf"
                  type="submit"
                  disabled={
                    !postalFormik.isValid || !postalFormik.dirty || loading2
                  }
                >
                  {loading2 ? "Checking..." : "Apply"}
                </button>
              </form>
              {ispostalvalid && (
                <>
                  {localities && (
                    <Selector
                      selected={selectedLocality}
                      setSelected={setselectedLocality}
                      data={localities}
                      className="selector-rounded"
                    />
                  )}
                  <InputField
                    label="Number"
                    type="text"
                    placeholder="Number"
                    onChange={subscriptionFormik.handleChange}
                    onBlur={subscriptionFormik.handleBlur}
                    value={subscriptionFormik.values.storeAddress}
                    error={
                      subscriptionFormik.touched.storeAddress &&
                      subscriptionFormik.errors.storeAddress
                    }
                    name="storeAddress"
                  />
                  <InputFieldCity
                    type="text"
                    disabled
                    onBlur={subscriptionFormik.handleBlur}
                    // value={subscriptionFormik.values.city}
                    error={
                      subscriptionFormik.touched.city &&
                      subscriptionFormik.errors.city
                    }
                    name="city"
                    value={city}
                  />
                </>
              )}
            </div>
          </div>

          <div className="box-4">
            <h2>Membership details</h2>
            <div className="box">
              <div className="col">
                <h6>Membership Selected:</h6>
                <span>{queryParams.name}</span>

                <h6>Total Amount:</h6>
                <span>
                  £{" "}
                  {(
                    queryParams.price -
                    (couponDetails.percentageOff / 100) * queryParams.price
                  ).toFixed(2)}
                  {couponDetails.percentageOff !== 0
                    ? ` (${couponDetails.percentageOff}% off)`
                    : ""}
                </span>

                <p>
                  You will be charged £{queryParams.price} per month starting
                  from {couponDetails?.startAt}. Your current subscription will
                  update when your previous subscription expires.
                </p>

                <form className="bottom" onSubmit={couponFormik.handleSubmit}>
                  <div className="top">
                    <InputField
                      style={{ fontSize: "1.5rem" }}
                      label="Promo Code"
                      placeholder="Enter Promo Code"
                      name="couponId"
                      onChange={couponFormik.handleChange}
                      onBlur={couponFormik.handleBlur}
                      value={couponFormik.values.couponId}
                    />
                  </div>
                  <div className="bottom">
                    <button
                      className="button-9274982734987324afasdf"
                      type="submit"
                      disabled={
                        !couponFormik.isValid || !couponFormik.dirty || loading2
                      }
                    >
                      {loading2 ? "Checking..." : "Apply"}
                    </button>
                  </div>
                </form>
              </div>
              <div className="row">
                <MemberShipCard
                  className={`bg-${queryParams.name}`}
                  name={queryParams.name}
                  price={queryParams.price}
                  features={vendorPackages}
                ></MemberShipCard>
                {/* <span>{queryParams.name}</span> */}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <button
            disabled={
              subscriptionLoading ||
              !subscriptionFormik.isValid ||
              !subscriptionFormik.dirty
            }
            type="submit"
            className="button-9274982734987324afasdf"
            onClick={() => setIsConfirmModalOpen(true)}
          >
            {subscriptionLoading
              ? "Upgrading..."
              : `Upgrade to ${queryParams?.name}`}
          </button>
        </div>
      </div>

      <ConfirmationModal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirmation={() => subscriptionFormik.handleSubmit()}
        disabled={subscriptionLoading || !subscriptionFormik.isValid}
      />
    </>
  );
};

const CheckoutFormWithStripeWrapper = () => (
  <Elements stripe={stripePromise}>
    <UpgradePlain />
  </Elements>
);

export default CheckoutFormWithStripeWrapper;

const InputField = ({ label, error, ...rest }) => {
  return (
    <label className="InputField-2349823981nidsfwe">
      <h6>{label}</h6>
      <div>
        <input {...rest} />
        {error && <p>{error}</p>}
      </div>
    </label>
  );
};

const InputFieldCity = ({ error, onSelectFromDropdown, ...rest }) => {
  return (
    <label className="InputField-2349823981nidsfwe">
      <h6>Address</h6>
      <div>
        <GoogleAutoComplete {...rest} />
        {error && <p>{error}</p>}
      </div>
    </label>
  );
};

const PaymentForm = () => {
  return (
    <label className="InputField-2324232098238">
      <h6>Card Detail</h6>
      <div>
        <CardElement
          className="stripe-input-23423824sd"
          options={{ hidePostalCode: true, disableLink: true }}
        />
      </div>
    </label>
  );
};

const ConfirmationModal = ({ open, onClose, onConfirmation, disabled }) => {
  return (
    <Modal
      open={open}
      onClose={!disabled && onClose}
      onConfirmation={onConfirmation}
      className="confirmation-modal-lksadjf239jf2"
    >
      <div className="confirmation-modal-239jv2">
        <div className="top">
          <h6>Confirm Payment</h6>
          <p>Are you sure you want to upgrade to this plan?</p>
        </div>
        <div className="bottom">
          <button
            onClick={onConfirmation}
            className="button-9274982734987324afasdf"
            disabled={disabled}
          >
            {disabled ? "Upgrading..." : "Yes"}
          </button>
          <button
            onClick={onClose}
            className="button-sdkjfkj2939j3f23"
            disabled={disabled}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};
